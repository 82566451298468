// App.tsx
import React, { useEffect } from 'react';
import { analytics } from './firebase';
import { logEvent } from 'firebase/analytics';
import MainView from './MainVIew';

function App() {
  useEffect(() => {
    // Log a page view event when the component mounts
    logEvent(analytics, 'page_view');
  }, []);

  return (
    <div className="App">
      <MainView />
    </div>
  );
}

export default App;